import React, { useEffect, useState } from "react";
import { colors } from "../../theme";
import { useAppContext } from "../../context";
import { SaveReportStep, SymptomSelectStep } from "./StepComponents";
import { EMERGENCY_SYMPTOMS, NewFaultReport } from "../../models";
import { FaultReportSuccessDialog } from "./FaultReportDialog";
import { LocationIcon, ThunderIcon, UserIcon } from "../../assets/icons";
import { ImageIO, useImageApi } from "../../dataQuery/imageApi";
import { EmergencyDialog } from "./EmergencyDialog";
import { useFaultReportsApi } from "../../dataQuery";
import { Stepper } from "../Stepper";
import { GeoSearchBar } from "../GeoSearchBar";
import { FaultReportErrorType, LionError } from "../../context/ErrorContext/LionError";
import { useErrorContext } from "../../context/ErrorContext/ErrorContext";
import { useAppNavigate } from "../../hooks/navigation/useAppNavigate";
import { AppRoute } from "../../hooks/navigation/routes";
import { useEnersisGeoMap } from "../../context/EnersisGeoMapContext";
import {LoadingDialog} from "../../parts/Spinner/LoadingDialog";

const defaultFaultReport: NewFaultReport = {
  street: "",
  houseNo: undefined,
  zipCode: "",
  city: "",
  cityDistrict: undefined,
  netArea: undefined,
  lightingNo: "",
  controlCabinetNo: "",
  symptoms: [],
  recognitionDateTime: new Date(),
  symptomOther: undefined,
  remarksMunicipality: undefined,
};

export const CreateFaultReportBox = () => {
  const [faultReport, setFaultReport] = useState(defaultFaultReport);
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState<ImageIO[]>([]);
  const { customerScope } = useAppContext();
  const imageApi = useImageApi();
  const faultReportsApi = useFaultReportsApi();
  const navigate = useAppNavigate();
  const [saveSuccessDialogIsOpen, setSaveSuccessIsOpen] = useState(false);
  const [openEmergencyDialog, setOpenEmergencyDialog] = useState(false);
  const { setError } = useErrorContext();
  const { selectedLightPoint, setSelectedLightPoint, loadLightpoints } = useEnersisGeoMap();
  const [isLoadingSpinnerVisible, showLoadingSpinner] = useState(false);

  useEffect(() => {
    setFaultReport({ ...defaultFaultReport });
  }, [setFaultReport]);

  const handleSubmit = async () => {
    if (!customerScope) return;
    const newFaultReport: NewFaultReport = {
      ...faultReport,
      city: selectedLightPoint?.city || "",
      houseNo: selectedLightPoint?.houseNumber,
      // cityDistrict: selectedLightPoint?.district, // TODO Check ob wir das noch brauchen
      lightingNo: selectedLightPoint?.number || "",
      controlCabinetNo: selectedLightPoint?.cabinetNumber,
      netArea: selectedLightPoint?.netArea,
      street: selectedLightPoint?.street || "",
      zipCode: selectedLightPoint?.zipCode || "",
    };
    const validationResult = validateFaultReport(newFaultReport, customerScope);
    if (validationResult?.error) {
      return setError(
        new LionError(FaultReportErrorType.FAULTREPORT_CREATE, { title: "", message: validationResult.message })
      );
    }

    try {
      showLoadingSpinner(true);
      await createIncident(newFaultReport);
      showLoadingSpinner(false);
      loadLightpoints();
      setSaveSuccessIsOpen(true);
    } catch (e) {
      showLoadingSpinner(false);
    }
  };

  const createIncident = async (newFaultReport: NewFaultReport) => {
    const createdReport = await faultReportsApi.post(newFaultReport);
    if (images.length) {
      for (let i = 0; i < images.length; i += 2) {
        await imageApi.post(createdReport.uuid, images.slice(i, i + 2));
      }
    }
  }

  const handleClickNewReport = () => {
    navigate(AppRoute.CREATE_REPORT);
  };

  const handleCancel = () => {
    navigate(AppRoute.COCKPIT);
    setSelectedLightPoint(undefined);
    loadLightpoints();
  };

  const checkSubmitDisabled = () => {
    switch (activeStep) {
      case 0:
        return !selectedLightPoint || !!selectedLightPoint.incident;
      case 1: {
        const isEmergency = faultReport.symptoms.some((s) => EMERGENCY_SYMPTOMS.includes(s));
        if (isEmergency) {
          setOpenEmergencyDialog(true);
          setFaultReport((f) => ({
            ...f,
            symptoms: f.symptoms.filter((s) => !EMERGENCY_SYMPTOMS.includes(s)),
          }));
          return true;
        }
        return faultReport.symptoms.length < 1;
      }
    }
    return false;
  };

  const getColor = (index: number) => (activeStep === index ? colors.secondary.main : colors.primary.main);

  return (
    <>
      <Stepper
        title="Neue Störungsmeldung"
        isNextButtonDisabled={checkSubmitDisabled}
        onCancel={handleCancel}
        onActiveStepChange={(i) => setActiveStep(i)}
        activeStep={activeStep}
        steps={[
          {
            label: "Leuchtstelle",
            icon: LocationIcon(getColor(0)),
            content: <GeoSearchBar title="Wählen Sie bitte eine Leuchtstelle aus" />,
            submitBtnLabel: "Weiter",
          },
          {
            label: "Störung",
            icon: ThunderIcon(getColor(1)),
            content: (
              <SymptomSelectStep
                value={{
                  symptomOther: faultReport.symptomOther || "",
                  symptoms: faultReport.symptoms,
                }}
                onSelectSymptoms={(s) => setFaultReport({ ...faultReport, symptoms: s })}
                onSymptomOther={(s) => setFaultReport({ ...faultReport, symptomOther: s })}
                onChangeImages={setImages}
                onShowSpinner={showLoadingSpinner}
              />
            ),
            submitBtnLabel: "Weiter",
          },
          {
            label: "Meldung",
            icon: UserIcon(getColor(2)),
            content: (
              <SaveReportStep
                value={{
                  recognitionDateTime: faultReport.recognitionDateTime,
                  remarks: faultReport.remarksMunicipality || "",
                }}
                onChangeRemarksMunicipality={(v) => setFaultReport({ ...faultReport, remarksMunicipality: v })}
                onRecognitionDateTimeChange={(d) => setFaultReport({ ...faultReport, recognitionDateTime: d })}
              />
            ),
            submitBtnLabel: "Speichern",
            onSubmit: handleSubmit,
          },
        ]}
        style={{
          padding: "24px",
          backgroundColor: "white",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "50px",
          boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.05)",
        }}
      />
      <LoadingDialog open={isLoadingSpinnerVisible} />
      <FaultReportSuccessDialog
        onNewReportClick={handleClickNewReport}
        open={saveSuccessDialogIsOpen}
        onSaveDialogClose={handleCancel}
      />
      <EmergencyDialog
        open={openEmergencyDialog}
        onClose={() => setOpenEmergencyDialog(false)}
        emergencySymptoms={EMERGENCY_SYMPTOMS}
      />
    </>
  );
};

function isValidLightingNo(value: string): boolean {
  if (!value) return true;
  return /[A-Z]{5}-(SOLAR|PRIVA|\d{5})-\d{5}/.test(value);
}

function isValidDate(date: Date | null): boolean {
  return date === null || date.getTime() - 1000 < Date.now();
}

interface ValidationErrorResult {
  error: boolean;
  message: string;
}
type ValidationResult = ValidationErrorResult | undefined;

function validateFaultReport(faultReport: NewFaultReport, customerScope: string): ValidationResult {
  if (!faultReport.city) {
    return {
      error: true,
      message: "Ort muss angegeben werden.",
    };
  }
  if (!faultReport.lightingNo) {
    return {
      error: true,
      message: "Leuchtstellennummer muss angegeben werden.",
    };
  }
  if (!isValidLightingNo(faultReport.lightingNo)) {
    return {
      error: true,
      message: "Leuchtstellennummer hat falsches Format.",
    };
  }
  if (faultReport.symptoms.length === 0) {
    return {
      error: true,
      message: "Mindestens ein Defekt muss ausgewählt werden.",
    };
  }
  if (!isValidDate(faultReport.recognitionDateTime)) {
    return {
      error: true,
      message: "Zeitpunkt der Störung darf nicht in der Zukunft liegen.",
    };
  }
  if (!customerScope) {
    return {
      error: true,
      message: "Kundenkennung fehlt.",
    };
  }
}
